import DateUtils from '@date-io/date-fns';
import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns-tz/toDate';
import { parsePhoneNumber, parseIncompletePhoneNumber } from 'libphonenumber-js/max';

const dateUtils = new DateUtils();

const format = (date, FORMAT) => {
  const newDate = typeof date === 'string' ? toDate(date) : date;
  try {
    return dateUtils.format(newDate, FORMAT);
  } catch (e) {
    throw new Error(`error formatting date ${newDate}`, e);
  }
};

export const numberFormatter = (valueIn, fraction = 0) => {
  const value = typeof valueIn === 'number' ? valueIn : Number(valueIn);
  return value.toLocaleString('en-US', {
    maximumFractionDigits: fraction,
    minimumFractionDigits: fraction ? 2 : 0
  });
};

export const numberAbbrFormatter = (value) => {
  let newValue = value;
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum += 1;
  }

  if (!Number.isInteger(newValue)) {
    newValue = Number(newValue).toPrecision(3);
  }

  newValue += suffixes[suffixNum];
  return newValue;
};

// yyyy must be lowercase in order for material-ui-pickers to work well
export const DATE_FORMAT = 'MM/dd/yyyy';
export const TIME_FORMAT = 'H:mm aa';
export const DATE_TIME_FORMAT = 'MM/dd/yyyy @ H:mm aa';
export const AWS_DATE_FORMAT = 'yyyy-MM-dd';

export const dateFormatter = (date) => {
  return format(date, DATE_FORMAT);
};

export const timeFormatter = (date) => {
  return format(date, TIME_FORMAT);
};

export const dateTimeFormatter = (date) => {
  return format(date, DATE_TIME_FORMAT);
};

export const awsDateFormatter = (date) => {
  return format(date, AWS_DATE_FORMAT);
};

export const stringDateToAwsDate = (stringDate) => {
  const values = stringDate.split('/');
  return `${values[2]}-${values[0]}-${values[1]}`;
};

export const awsDateToStringDate = (awsDateString) => {
  return dateFormatter(awsDateToJs(awsDateString));
};

export const awsDateToDateFormatter = (awsDate) => {
  return format(awsDate, DATE_FORMAT);
};

export const awsDateToJs = (awsDate) => {
  return parseISO(awsDate);
};

export const awsDateTimeFormatter = (date) => {
  return date.toISOString();
};

export const booleanFormatter = (value) => {
  return value ? 'Yes' : 'No';
};

export const awsDateToDateObject = (awsDate) => {
  return parseISO(awsDate);
};

export const addressFormatter = (mailingAddress) =>
  `${mailingAddress.address}${mailingAddress.address2 ? ` ${mailingAddress.address2}` : ''}${
    mailingAddress.city ? `, ${mailingAddress.city}` : ''
  }${mailingAddress.state ? `, ${mailingAddress.state}` : ''}${mailingAddress.zip ? ` ${mailingAddress.zip}` : ''}${
    mailingAddress.country ? ` ${mailingAddress.country}` : ''
  }`;

export const capitalize = (text) => {
  if (!text) {
    return '';
  }
  const words = text.split(' ');
  return words.map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`).join(' ');
};

export const capitalizeWords = (text) => {
  const words = text.match(/([$]*[0-9]+[A-Za-z]*|[A-Za-z][a-z]*)/g) || [];
  return words.map(capitalize).join(' ');
};

export const toCapitalized = (text = '') => {
  const phrases = String(text).split(',');
  const capitalized = phrases.map(capitalizeWords);
  return capitalized.join(', ');
};

export const currencyFormatter = (amount, options = { removeNegativeSign: false }) => {
  const amountString = `$${
    amount
      ? amount.toLocaleString('en-US', {
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : '0.00'
  }`;

  if (options.removeNegativeSign) {
    // "$-25.20" => "$25.50"
    return amountString.replace(/-/g, '');
  }
  return amountString;
};

export const getTotalPrice = (premium, fees, surplusContribution = 0) => {
  const feesTotal = fees.reduce((val, fee) => fee.amount + val, 0);
  return feesTotal + premium + surplusContribution;
};

export const cleanObject = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return {};
  }
  const cleanedObject = obj;
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete cleanedObject[key];
    }
  });
  return cleanedObject;
};

export const mapAutoDiscountValues = ({
  attachedHomeowners,
  attachedCondo,
  attachedRenters,
  includeRenters,
  includeUmbrella
}) => {
  let discount = '';
  if (attachedHomeowners) {
    discount = 'H';
  }

  if (attachedCondo) {
    discount = 'C';
  }

  if (includeRenters || attachedRenters) {
    discount = `${discount}R`;
  }
  if (includeUmbrella) {
    discount = `${discount}U`;
  }
  return discount.length > 0 ? discount : 'N';
};

export const getAutoMPD = (autoMultiPolicyDiscount) => {
  // we mistakenly bind with the home version of the MPD on auto policies
  // ex: 'A' to 'H', 'AU' to 'HU'
  if (autoMultiPolicyDiscount.includes('A')) {
    return autoMultiPolicyDiscount.replace('A', 'H');
  }
  return autoMultiPolicyDiscount;
};

export const mapHomeDiscountValues = ({
  attachedAuto,
  attachedRenters,
  includeRenters,
  includeUmbrella,
  multiPolicyDiscount,
  hasSecondHome
}) => {
  let discount = '';
  if (attachedAuto) {
    discount = 'A';
  }
  if (includeRenters || attachedRenters || multiPolicyDiscount?.includes('R')) {
    discount = `${discount}R`;
  }
  if (includeUmbrella) {
    discount = `${discount}U`;
  }
  if (multiPolicyDiscount?.includes('S') || hasSecondHome) {
    // if it had a secondary home on it, then leave it
    discount = `${discount}S`;
  }
  return discount.length > 0 ? discount : 'N';
};

/*
 * This function is used to correct attached endorsements based on the multi policy discount if the
 * the multi policy discount was not manually edited
 */
export const mapDiscountToInputs = ({ multiPolicyDiscount }) => {
  return {
    attachedRenters: multiPolicyDiscount?.includes('R'),
    attachedHomeowners: multiPolicyDiscount?.includes('H'),
    includeUmbrella: multiPolicyDiscount?.includes('U'),
    attachedAuto: multiPolicyDiscount?.includes('A'),
    attachedCondo: multiPolicyDiscount?.includes('C')
  };
};

export const numberThousandsFormatter = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const phoneNumberFormatter = ({ phoneNumber }) => {
  // E164 format like +12123456789
  return parsePhoneNumber(parseIncompletePhoneNumber(phoneNumber.trim()), 'US').number;
};

export const nthDayFormatter = (d) => {
  if (d > 10 && d < 14) {
    return `${d}th`;
  }
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
};
